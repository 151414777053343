import isEmpty from 'lodash/isEmpty'

import { formatVnCurrency } from '@core/utils/filter'

export function getLocaleFullName(obj) {
  if (isEmpty(obj)) return ''
  const locale = localStorage.getItem('Locale') || 'vi'
  const pattern = /(MISS|MSTR)$/
  const firstName = (obj?.firstName.replace(pattern, '')) || ''
  const arr = locale === 'vi'
    ? [obj?.lastName, firstName]
    : [firstName, obj?.lastName]
  return arr.filter(name => !isEmpty(name)).join(' ')
}

export function formatCurrency(val) {
  if (!val || !Number(val)) return ''
  return formatVnCurrency(val)
}

export function resolveFightTypeFromTicketType(ticketType) {
  if (ticketType === 'DOMESTIC') return 'Domestic'
  if (ticketType === 'INTERNATIONAL') return 'Inbound'
  return ''
}
